import { formatMonetario } from "@/services/utils";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import LaunchIcon from "@mui/icons-material/Launch";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useCallback, useRef, useState } from "react";
import QRCode from "react-qr-code";
import DialogFinalizacaoTransacao from "./DialogFinalizacaoTransacao";

import BotaoText from "@/components/BotaoText";
import {
  setInfoDialogLinkTransacao,
  setInfoToaster,
} from "@/store/reducers/geral";
import { useDispatch, useSelector } from "react-redux";
export default function DialogLinkTransacao() {
  const [infoDialogFinalizacaoTransacao, setInfoDialogFinalizacaoTransacao] =
    useState({});
  const exportRef = useRef();
  const dispatch = useDispatch();
  const geral = useSelector((state) => state.geral);

  const cancelar = useCallback(() => {
    dispatch(setInfoDialogLinkTransacao({ open: false }));
  }, [dispatch]);

  const downloadQrCode = () => {
    const svg = exportRef.current;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const retornaMensagemCobrancaGerada = () => {
    return geral.infoDialogLinkTransacao.payment_method === "pix"
      ? "PIX gerado com sucesso"
      : "Cobrança em cartão de crédito gerada com sucesso";
  };

  return (
    <div>
      <Dialog
        open={geral.infoDialogLinkTransacao.open}
        maxWidth={"sm"}
        onClose={cancelar}
        aria-labelledby="alert-dialog-title"
        className="dialog-transacao"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <IconButton
            aria-label="close"
            onClick={cancelar}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={0}>
            <Grid size={12}>
              <Alert severity="success">
                <Typography>
                  {geral.infoDialogLinkTransacao.id
                    ? "Cobrança em " + retornaMensagemCobrancaGerada()
                    : "Criar cobrança"}
                </Typography>
                <Typography>
                  Cliente: <b>{geral.infoDialogLinkTransacao.client_name}</b>
                </Typography>
                <Typography>
                  Valor solicitado:{" "}
                  <b>{formatMonetario(geral.infoDialogLinkTransacao.amount)}</b>
                </Typography>
                <Typography>
                  Valor total:{" "}
                  <b>
                    {formatMonetario(geral.infoDialogLinkTransacao.total)}
                    {geral.infoDialogLinkTransacao.payment_method ===
                      "credit_card" && (
                      <>{` em ${geral.infoDialogLinkTransacao.installments}x`}</>
                    )}
                  </b>
                </Typography>
              </Alert>
            </Grid>
            <Grid sx={{ mt: 3 }} size={12}>
              <a
                style={{ textDecoration: "none", color: "black" }}
                href={geral.infoDialogLinkTransacao.payment_link}
                target="blank">
                <div
                  style={{
                    wordWrap: "break-word",
                    cursor: "pointer",
                    padding: "5px",
                    border: "solid",
                    borderColor: "#cfcfcf",
                    borderWidth: "1px",
                  }}>
                  {geral.infoDialogLinkTransacao.payment_link}
                </div>
              </a>
            </Grid>
            <Grid align="center" size={12}>
              <BotaoText
                startIcon={<ContentCopyIcon />}
                onClick={() => {
                  navigator.clipboard.writeText(
                    geral.infoDialogLinkTransacao.payment_link
                  );
                  dispatch(
                    setInfoToaster({
                      open: true,
                      message: "Copiado",
                      type: "success",
                    })
                  );
                }}>
                COPIAR LINK PARA PAGAMENTO
              </BotaoText>

              <BotaoText
                component="label"
                startIcon={<LaunchIcon />}
                onClick={() => {
                  window.open(
                    geral.infoDialogLinkTransacao.payment_link,
                    "_blank"
                  );
                }}>
                ABRIR LINK
              </BotaoText>
            </Grid>
            {geral.infoDialogLinkTransacao.payment_link && (
              <>
                <Grid style={{ marginTop: "1rem" }} align="center" size={12}>
                  <QRCode
                    ref={exportRef}
                    size={500}
                    style={{
                      height: "auto",
                      maxWidth: "300px",
                      width: "100%",
                    }}
                    value={geral.infoDialogLinkTransacao.payment_link}
                    viewBox={`0 0 256 256`}
                  />
                </Grid>
                <Grid sx={{ mb: 2 }} size={12}>
                  <BotaoText
                    component="label"
                    startIcon={<DownloadIcon />}
                    onClick={() => {
                      downloadQrCode();
                    }}
                    fullWidth>
                    BAIXAR QR Code
                  </BotaoText>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <BotaoText onClick={cancelar}>Fechar</BotaoText>
        </DialogActions>
      </Dialog>
      {infoDialogFinalizacaoTransacao.open && (
        <DialogFinalizacaoTransacao
          {...{
            infoDialogFinalizacaoTransacao,
            setInfoDialogFinalizacaoTransacao,
          }}
        />
      )}
    </div>
  );
}
