import { lazy } from "react";
import Loadable from "@/components/Loadable";
import MinimalLayout from "@/layout/MinimalLayout";

const RecuperarSenha = Loadable(
  lazy(() => import("@/pages/area-aberta/recuperacao-senha"))
);
const EsqueciMinhaSenha = Loadable(
  lazy(() => import("@/pages/area-aberta/esqueci-minha-senha"))
);
const Cadastro = Loadable(lazy(() => import("@/pages/area-aberta/cadastro")));

const Pagamento = Loadable(lazy(() => import("@/pages/area-aberta/pagamento")));

const FinalizacaoCadastroParceiro = Loadable(
  lazy(() => import("@/pages/area-aberta/cadastro/finalizacao"))
);
const Login = Loadable(lazy(() => import("@/pages/area-aberta/login")));

const AcessoAdmin = Loadable(
  lazy(() => import("@/pages/area-aberta/login/AcessoAdmin"))
);

const AreaAbertaRoutes = {
  path: "app",
  element: <MinimalLayout />,
  children: [
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "acesso-admin/:company/:token/",
      element: <AcessoAdmin />,
    },
    {
      path: "acesso-admin/:company/:token/:tela",
      element: <AcessoAdmin />,
    },
    {
      path: "cadastro/etapa/:etapa",
      element: <Cadastro />,
    },
    {
      path: "login-social/etapa/:etapa/:id/:token",
      element: <Cadastro />,
    },
    {
      path: "finalizacao-cadastro",
      element: <FinalizacaoCadastroParceiro />,
    },
    {
      path: "client/:tipo/:id/:token",
      element: <Pagamento />,
    },
    {
      path: "client/:tipo/:id/:token/gateway/:gateway",
      element: <Pagamento />,
    },
    {
      path: "client/:tipo/:id/:token/:etapa",
      element: <Pagamento />,
    },

    {
      path: "recuperar-senha/:token",
      element: <RecuperarSenha />,
    },
    {
      path: "esqueci-minha-senha",
      element: <EsqueciMinhaSenha />,
    },
    {
      path: "*",
      element: <Login />,
    },
  ],
};

export default AreaAbertaRoutes;
