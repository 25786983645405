import BotaoPrincipal from "@/components/BotaoPrincipal";
import BotaoText from "@/components/BotaoText";
import { formatMonetario, retornaFormaPagamento } from "@/services/utils";
import {
  activeSpinner,
  cancelSpinner,
  setInfoToaster,
} from "@/store/reducers/geral";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import html2canvas from "html2canvas";
import Moment from "moment";
import { useRef } from "react";
import { useDispatch } from "react-redux";

export default function DialogFinalizacaoTransacao({
  infoDialogFinalizacaoTransacao,
  setInfoDialogFinalizacaoTransacao,
}) {
  const dispatch = useDispatch();
  const exportRef = useRef();

  const cancelar = () => {
    setInfoDialogFinalizacaoTransacao({ open: false });
  };

  const baixarComprovante = async () => {
    dispatch(activeSpinner());
    const canvas = await html2canvas(exportRef.current);
    const link = document.createElement("a");
    link.download = "comprovante-movipay.png";
    link.href = canvas.toDataURL();
    link.click();
    dispatch(
      setInfoToaster({
        open: true,
        message: "Copiado",
        type: "success",
      })
    );
    dispatch(cancelSpinner());
  };
  return (
    <Dialog
      open={infoDialogFinalizacaoTransacao.open}
      maxWidth={"sm"}
      onClose={cancelar}
      aria-labelledby="alert-dialog-title"
      className="dialog-transacao"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <IconButton
          aria-label="close"
          onClick={cancelar}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid align="center" size={12}>
            <CheckCircleOutlineIcon
              style={{
                color: "#5cb660",
                fontSize: "5rem",
              }}></CheckCircleOutlineIcon>
          </Grid>
          <Grid size={12}>
            <Alert severity="success" style={{ width: "100%" }}>
              <Typography>Pagamento realizado com sucesso</Typography>
            </Alert>
          </Grid>
          <Grid sx={{ mb: 1, mt: 1 }} align="center" size={12}>
            <BotaoPrincipal
              component="label"
              startIcon={<ReceiptIcon />}
              onClick={() => {
                baixarComprovante();
              }}>
              BAIXAR COMPROVANTE
            </BotaoPrincipal>
          </Grid>
          <Grid size={12}>
            <Paper
              variant="outlined"
              sx={{ p: { xs: 2, md: 3 } }}
              ref={exportRef}>
              <Typography align="center" sx={{ mb: 1 }}>
                Comprovante de pagamento - Movipay
              </Typography>
              <Typography>
                ID do pedido:{" "}
                <b>{infoDialogFinalizacaoTransacao.id_transaction}</b>
              </Typography>
              <Typography>
                Valor:{" "}
                <b>{formatMonetario(infoDialogFinalizacaoTransacao.amount)}</b>
              </Typography>
              <Typography>
                Data pagamento:{" "}
                <b>
                  {Moment(
                    infoDialogFinalizacaoTransacao.payment_datetime
                  ).format("DD/MM/YYYY HH:mm")}
                </b>
              </Typography>
              {infoDialogFinalizacaoTransacao.payment_method === "pix" && (
                <Typography>
                  Forma de pagamento:{" "}
                  <b>
                    {retornaFormaPagamento(
                      infoDialogFinalizacaoTransacao.payment_method
                    )}
                  </b>
                </Typography>
              )}
              {infoDialogFinalizacaoTransacao.payment_method !== "pix" && (
                <Typography>
                  Forma de pagamento:{" "}
                  <b>
                    {retornaFormaPagamento(
                      infoDialogFinalizacaoTransacao.payment_method
                    )}{" "}
                    em {infoDialogFinalizacaoTransacao.installments}x
                  </b>
                </Typography>
              )}
              <Typography>
                Cliente: <b>{infoDialogFinalizacaoTransacao.client_name}</b>
              </Typography>
              <Typography>
                Token de autenticação:{" "}
                <b>{infoDialogFinalizacaoTransacao.token}</b>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <BotaoText onClick={cancelar}>Fechar</BotaoText>
      </DialogActions>
    </Dialog>
  );
}
