import PropTypes from "prop-types";

import { getTopBarColor } from "@/services/utils";
import { useSelector } from "react-redux";
import DrawerHeaderStyled from "./DrawerHeaderStyled";
const DrawerHeader = ({ open }) => {
  const user = useSelector((state) => state.user);

  return (
    <DrawerHeaderStyled
      open={open}
      style={{ backgroundColor: getTopBarColor(user) }}></DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
