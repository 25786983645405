import BotaoCadastrar from "@/components/BotaoCadastrar";
import menuItem from "@/menu-items";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router";
import NavGroup from "./NavGroup";

const Navigation = () => {
  const navigation = useNavigate();

  const navGroups = menuItem.items.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  const criarLinkPagamento = async () => {
    navigation("/app/area-cliente/criar-cobranca/etapa/1");
  };

  return (
    <Box>
      <Grid container sx={{ pb: 1, pt: 1 }}>
        <Grid align="center" size={12}>
          <BotaoCadastrar
            sx={{ mb: 1 }}
            onClick={() => {
              criarLinkPagamento();
            }}>
            CRIAR COBRANÇA
          </BotaoCadastrar>
        </Grid>
      </Grid>
      {navGroups}
    </Box>
  );
};

export default Navigation;
