import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BusinessIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HistoryIcon from "@mui/icons-material/History";
import LinkIcon from "@mui/icons-material/Link";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SettingsIcon from "@mui/icons-material/Settings";

const dashboard = {
  id: "group-dashboard",
  title: "",
  type: "group",
  url: "/dashboard",
  children: [
    {
      id: "dashboard",
      title: "INÍCIO",
      type: "item",
      url: "",
      icon: DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: "meus-saldos",
      title: "MEUS SALDOS",
      type: "item",
      url: "meus-saldos",
      icon: AttachMoneyIcon,
      breadcrumbs: false,
    },
    {
      id: "links-pagamento",
      title: "LINKS DE PAGAMENTO",
      type: "item",
      url: "links-pagamento",
      icon: LinkIcon,
      breadcrumbs: false,
    },
    {
      id: "transacoes",
      title: "TRANSAÇÕES",
      type: "item",
      url: "transacoes",
      icon: HistoryIcon,
      breadcrumbs: false,
    },

    {
      id: "clientes",
      title: "CLIENTES",
      type: "item",
      url: "clientes",
      icon: PeopleAltIcon,
      breadcrumbs: false,
    },

    {
      id: "empresas",
      title: "EMPRESAS",
      type: "item",
      url: "empresas",
      icon: BusinessIcon,
      permissao: "visualizar-empresas",
      subconta: true,
    },

    {
      id: "configuracoes",
      title: "CONFIGURAÇÕES",
      type: "item",
      url: "configuracoes",
      icon: SettingsIcon,
      permissao: "configuracoes",
    },
  ],
};

export default dashboard;
